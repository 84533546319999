
import Wrapper from 'components/Wrapper'
import Manage from 'components/Quotations/Manage'
import React from 'react'

export default function ManageQuote() {

  return (
    <Wrapper>
      <div className="content">
        <Manage />
      </div>
    </Wrapper>
  )
}
