import InputField from "components/Fields/InputField";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import helpers from "../../store/helpers";

export default function Item({
  index,
  data,
  setValues,
  removeItem,
  formErrors,
}) {
  const onChangeTitle = (e) => {
    data.name = e.target.value;
    setValues(index, data);
  };
  const onChangeCost = (e) => {
    data.cost = helpers.getInputValue(e);
    data.profit = helpers.getIntVal(
      (helpers.getIntVal(data.margin) * helpers.getIntVal(data.cost)) / 100
    );
    data.total = helpers.getIntVal(
      helpers.getIntVal(data.profit) + helpers.getIntVal(data.cost)
    );
    setValues(index, data);
  };
  const onChangeMargin = (e) => {
    data.margin = helpers.getInputValue(e);
    data.profit = helpers.getIntVal(
      (helpers.getIntVal(data.margin) * helpers.getIntVal(data.cost)) / 100
    );
    data.total = helpers.getIntVal(
      helpers.getIntVal(data.profit) + helpers.getIntVal(data.cost)
    );
    setValues(index, data);
  };
  const onChangeTotal = (e) => {
    data.total = helpers.getInputValue(e);
    data.cost = helpers.getIntVal(data.total) - helpers.getIntVal(data.profit);
    data.margin = helpers.getIntVal(
      (helpers.getIntVal(data.profit) / helpers.getIntVal(data.cost)) * 100
    );
    setValues(index, data);
  };
  const onChangeProfit = (e) => {
    data.profit = helpers.getInputValue(e);
    data.cost = helpers.getIntVal(data.total) - helpers.getIntVal(data.profit);
    data.margin = helpers.getIntVal(
      (helpers.getIntVal(data.profit) / helpers.getIntVal(data.cost)) * 100
    );
    setValues(index, data);
  };
  const onChangeComment = (e) => {
    data.comment = e.target.value;
    setValues(index, data);
  };

  const getErrorMessage = (field) => {
    if (data.errors && data.errors[field]) {
      return data.errors[field];
    }
    const key = `items.${index}.${field}`;
    if (formErrors && formErrors.errors && formErrors.errors[key])
      return formErrors.errors[key].map((error) => error.replace(key, field));
  };
  return (
    <tr className="p-3 mt-4">
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="title"
          placeholder="Item Name"
          label=""
          value={data.name}
          onChange={onChangeTitle}
          formError={getErrorMessage("name")}
        />
      </td>
      <td>
        <InputField
          id="cost"
          placeholder="Cost"
          label=""
          value={data.cost}
          onChange={onChangeCost}
          formError={getErrorMessage("cost")}
        />
      </td>
      <td>
        <InputField
          id="margin"
          placeholder="Margin(%)"
          label=""
          value={data.margin}
          onChange={onChangeMargin}
          formError={getErrorMessage("margin")}
        />
      </td>
      <td>
        <InputField
          id="total"
          placeholder="Total"
          label=""
          value={data.total}
          onChange={onChangeTotal}
          formError={getErrorMessage("total")}
        />
      </td>
      <td>
        <InputField
          id="profit"
          placeholder="Profit"
          label=""
          value={data.profit}
          onChange={onChangeProfit}
          formError={getErrorMessage("profit")}
        />
      </td>
      <td style={{ minWidth: "250px" }}>
        <InputField
          id="comment"
          placeholder="Comment"
          label=""
          value={data.comment}
          onChange={onChangeComment}
          formError={getErrorMessage("comment")}
        />
      </td>
      <td>
        <DeleteIcon
          onClick={() => removeItem(index)}
          className="text-danger cursor-p"
        />
      </td>
    </tr>
  );
}
