import GeneratePdf from "components/Quotations/GeneratePdf";
import Wrapper from "components/Wrapper";
import React from "react";

export default function CreateQuotePdf() {
  return (
    <Wrapper>
      <div className="content">
        <GeneratePdf />
      </div>
    </Wrapper>
  );
}
