// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import { Button, Modal } from "react-bootstrap";
// reactstrap components
import { Row, Col, } from "reactstrap";
import AxiosContext from '../store/axios-context';
import AuthContext from '../store/auth-context';
import { useHistory } from 'react-router';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import Dropdown from "./Fields/Dropdown";
import InputDate from "./Fields/InputDate";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function createData(id, jobType, project, party, status, action) {
  return {
    id,
    jobType,
    project,
    party,
    status,
    action,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Job Id',
      },
      {
        id: 'code',
        numeric: false,
        disablePadding: true,
        label: 'Job Code',
      },
      {
        id: 'jobType',
        numeric: false,
        disablePadding: false,
        label: 'Job Type',
      },
      {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project',
      },
      {
        id: 'party',
        numeric: false,
        disablePadding: false,
        label: 'Party',
      },
      {
        id: 'doe',
        numeric: false,
        disablePadding: false,
        label: 'Date of Execution',
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
      },
 
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function JobList() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const history = useHistory();

  const [status, setStatus] = useState('');
  // const [jobcode, setJobcode] = useState('');
  const [subtype, setSubtype] = useState('');
  const [project, setProject] = useState('');
  const [jobtype, setJobtype] = useState('');
  const [party, setParty] = useState('');
  const [port, setPort] = useState('');
  const [formError, setFormError] = useState(null);

  const [jobs, setJob] = useState([]);
  const [projects, setProjects] = useState([]);
  const [jobtypes, setJobtypes] = useState([]);
  const [parties, setParties] = useState([]);
  const [ports, setPorts] = useState([]);
  const [costs, setCosts] = useState([]);
  const [subtypes, setSubtypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const [filterStatus, setFilterStatus] = useState(null);
  const [filterSubtype, setFilterSubtype] = useState(null);
  const [filterProject, setFilterProject] = useState(null);
  const [filterJobtype, setFilterJobtype] = useState(null);
  const [filterParty, setFilterParty] = useState(null);

  const [airwaybillsLocalOrders, setAirwaybillsLocalOrders] = useState([]);
  const [airwaybillLocalOrder, setAirwaybillLocalOrder] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getPorts = () => {
    axiosCtx.instance.get(`port`)
      .then((response) => {
        setPorts(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getStatues = () => {
    axiosCtx.instance.get(`status`)
      .then((response) => {
        setStatuses(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getSubtypes = () => {
    axiosCtx.instance.get(`sub-type`)
      .then((response) => {

        setSubtypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const getJobs = () => {
    setLoading(true);
    axiosCtx.instance.get(`job-invoice`)
      .then((response) => {
        setLoading(false);
        setJob(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        setLoading(false);
      });
  };

  const getProjects = () => {
    axiosCtx.instance.get(`project`)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getJobtypes = () => {
    axiosCtx.instance.get(`job-type`)
      .then((response) => {
        setJobtypes(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    getStatues();
    getSubtypes();
    getPorts();
    getJobs();
    getJobtypes();
    getParties();
    getProjects();
    getAirwayBillsLocalOrders();
  }, []);

  const onJobEditHandler = (item) => {
    // history.push({
    //   pathname: 'job-details',
    //   state: { detail: item, page: 'index' }
    // });
  //   const jobDetailsUrl = `/job-details`;
  //   let localStorageKey = Object.keys(localStorage).find(key => key.startsWith('state_'));
  //   localStorage.removeItem(localStorageKey);
 
  // const tabId = Math.random().toString(36).substr(2, 9);

  // localStorageKey = `state_${tabId}`;

  // localStorage.setItem(localStorageKey, JSON.stringify({ detail: item, page: 'index' }));

  // const newTab = window.open(jobDetailsUrl, '_blank');
  
  const jobDetailsUrl = `/job-details`;

  // Create a query string with the state information
  const queryString = `?detail=${encodeURIComponent(JSON.stringify(item))}&page=index`;

  // Open the new tab with the URL containing query parameters
  window.open(jobDetailsUrl + queryString, '_blank');
  };

  const onJobDeleteHandler = (item) => {
    setDeleteJobId(item);
    handleShowDelete();
  };

  const deleteSubmitHandler = () => {
    setLoading(true);
    if (deleteJobId) {
      console.log(deleteJobId);
      axiosCtx.instance.delete(`job/` + deleteJobId,)
        .then((response) => {
          setLoading(false);
          setDeleteMessage(response.data.data);
          setDeleteJobId('');
          getJobs();
          handleCloseDelete();
        })
        .catch((error) => {
          setDeleteMessage(error.response.data.errors);
          setLoading(false);
        });
    }
  };

  const InvoiceZeroHandleChange = (event) => {
    setInvoiceZero(event.target.checked);
  }

  const InvoiceEmptyHandleChange = (event) => {
    setInvoiceEmpty(event.target.checked);
  }

  const [invoiceZero, setInvoiceZero] = useState(false);
  const [invoiceEmpty, setInvoiceEmpty] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleteJobId, setDeleteJobId] = useState('');
  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteMessage('');
    setDeleteJobId('');
  }
  const handleShowDelete = () => setShowDelete(true);

  const submitHandler = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);

    // Call login API
    const formData = new FormData();
    formData.append("job_type_id", (jobtype) ? jobtype.id : '');
    formData.append("party_id", (party) ? party.id : '');
    formData.append("project_id", (project) ? project.id : '');
    formData.append("sub_type_id", (subtype) ? subtype.id : '');
    formData.append("port_id", (port) ? port.id : '');

    axiosCtx.instance.post(`job`, formData)
      .then((response) => {
        setLoading(false);
        getJobs();
        setShow(false);
        // history.replace('job-list');
        // history.push({
        //   pathname: 'job-details',
        //   state: { detail: response.data, page: 'create' }
        // });
        const jobDetailsUrl = `/job-details`;

  // Create a query string with the state information
  const queryString = `?detail=${encodeURIComponent(JSON.stringify(response.data.data))}&page=create`;

  // Open the new tab with the URL containing query parameters
  window.open(jobDetailsUrl + queryString, '_blank');
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  };

  const filterSubmit = (event) => {
    event.preventDefault();
    setFormError(null);
    setLoading(true);

    // Call login API
    const formData = new FormData();
  
    formData.append("status_id", (filterStatus) ? filterStatus.id : '');
   
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    formData.append("invoice_zero", (invoiceZero && invoiceZero == true) ? 1 : '');
    formData.append("invoice_empty", (invoiceEmpty && invoiceEmpty == true) ? 1 : '');

    axiosCtx.instance.post(`job/invoice/filter`, formData)
      .then((response) => {
        console.log(response);
        setJob(response.data.data);
        filterClose();
        setLoading(false);
        // filterClose();
      })
      .catch((error) => {
        setFormError(error.response.data);
        setLoading(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = jobs.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setFormError(null);
    setStatus('');
    setJobtype('');
    setProject('');
    setSubtype('');
    setParty('');
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
  }

  const filterClear = () => {
    // setShowFilter(false);
    setFilterStatus(null);
    setFilterSubtype(null);
    setFilterProject(null);
    setFilterJobtype(null);
    setFilterParty(null);
    setStartDate('');
    setInvoiceZero(false);
    setInvoiceEmpty(false);
    setEndDate('');
    setAirwaybillLocalOrder(null);
    getJobs();
  }

  const getAirwayBillsLocalOrders = () => {
    axiosCtx.instance.get(`get-all-airwaybill-localorder`)
      .then((response) => {
        setAirwaybillsLocalOrders(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");

  return (
    <div className="content">
      {loading && loading == true ?
        <div className="custom-loader">
          <FadeLoader color={color} loading={loading} css={override} size={150} />
        </div>
        : ''
      }

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Job List with No Invoice</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                
                  {/* <Col md="6" className="mb-4">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox
                        checked={invoiceZero}
                        onChange={InvoiceZeroHandleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      } label="Invoice Amount 0" />
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mb-4">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox
                        checked={invoiceEmpty}
                        onChange={InvoiceEmptyHandleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      } label="Invoice Amount Empty" />
                    </FormGroup>
                  </Col> */}
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="statuses"
                      placeholder="Status"
                      label="name"
                      value={filterStatus}
                      options={statuses}
                      onChange={(event, newValue) => {
                        setFilterStatus(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Date of Execution Start"
                      label="From Date"
                      value={startDate}
                      onChange={(event) => {
                        setStartDate(event.target.value);
                      }}
                    />

                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Date of Execution End"
                      label="To Date"
                      value={endDate}
                      onChange={(event) => {
                        setEndDate(event.target.value);
                      }}
                    />
                  </Col>
                 
                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          {/* <div className="col-auto my-1">
            <button className="btn btn-primary" onClick={handleShow}>
              Create new job
            </button>
          </div> */}
        </div>

      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={jobs.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(jobs, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >

<TableCell component="th" id={labelId} scope="row" padding="none" > {row.job_id} </TableCell>
                        <TableCell>{row.job_code}</TableCell>
                        <TableCell>{row.job_type.type}</TableCell>
                        <TableCell>{row.project.name}</TableCell>
                        <TableCell>{row.party.name}</TableCell>
                        <TableCell>{row.date_of_execution}</TableCell>
                        <TableCell><span className="badge badge-danger py-1 px-2 ">{row.status.name}</span>  </TableCell>
                        {/* <TableCell><button onClick={() => onJobEditHandler(row)} className="btn btn-primary btn-sm font-weight-bold ">Edit</button>
                          <button onClick={() => onJobDeleteHandler(row.id)} className="btn btn-danger btn-sm font-weight-bold ">Delete</button></TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50,100,150]}
            component="div"
            count={jobs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>
    </div>
  );
}
