import CollectionDeliveryNotePdf from 'components/Pdf/CollectionDeliveryNotePdf';
import Wrapper from 'components/Wrapper';

function CollectionDeliveryNotePdfPage() {
  return (
    <Wrapper>
      <CollectionDeliveryNotePdf />
    </Wrapper>
  );
}

export default CollectionDeliveryNotePdfPage;
