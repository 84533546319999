import InvoiceJobList from "components/InvoiceJob";
import Wrapper from "components/Wrapper";
import React from "react";

export default function Quotations() {
  return (
    <Wrapper>
      <div className="content">
        <InvoiceJobList />
      </div>
    </Wrapper>
  );
}
