import List from "components/Quotations/List";
import Wrapper from "components/Wrapper";
import React from "react";

export default function Quotations() {
  return (
    <Wrapper>
      <div className="content">
        <List />
      </div>
    </Wrapper>
  );
}
