import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import logo from "assets/img/print-logo-invoice.png";
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Moment from 'moment';

const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: "calc(100vh - 94px)",
  },
  page: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 78,
    flexDirection: 'column',
    position: "relative",

  },
  header: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col6: {
    width: '50%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col7: {
    width: '65%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  col5: {
    width: '35%',
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },

  bold: {
    fontWeight: '600',
    fontSize: 12,
  },
  dflex: {
    display: 'flex',
  },

  thcol: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  tdcol: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginBottom: -1,
    marginRight: -1,
  },
  description: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '40%'
  },

  thHeading: {
    borderWidth: 1,
    borderColor: '#999',
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: -1,
    marginRight: -1,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
  },

  total: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    marginTop: 1,
    marginRight: -1,
    width: '50%',
    textAlign: 'right'
  },
  tdBorderless: {
    borderWidth: 1,
    borderColor: '#fff',
    borderBottomWidth: 1,
    padding: 5,
    flex: '1 1 auto',
    marginTop: 1,
    marginRight: -1,
  },

  marginY5: {
    marginTop: 25,
    marginBottom: 25
  },

  border: {
    borderWidth: 1,
    borderColor: '#999',
    paddingRight: 1,
    marginRight: -1,
    paddingBottom: 2
  },

  withoutBorder: {
    marginLeft: 25,
    textAlign: 'center',
    width: 160,
    fontSize: 8,
  },
  padding10: {
    padding: 10,
  },
  w100: {
    width: '100%',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerSub: {
    position: 'absolute',
    bottom: 50,
    left: 30,
    right: 30,
  },
  fixedBottom: {
    position: 'absolute',
    bottom: 0,
    left: 30,
    right: 30,

  }

});

function CollectionDeliveryNotePdf() {

  const location = useLocation();
//   console.log('location',location.state.data.job_id);
  let jobId = location.state.data.job_id;
  const history = useHistory();
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const [dateOfIssue, setdateOfIssue] = useState('');
  const [jobRef, setJobRef] = useState('');
  const [mawb, setMawb] = useState('');
  const [prefix, setPrefix] = useState('');
  const [hawb, setHawb] = useState('');
  const [vslFlt, setvslFlt] = useState('');
  const [partyName, setPartyName] = useState('');
  const [partyEmail, setPartyEmail] = useState('');
  const [price, setPrice] = useState('');
  const [shipperName, setShipperName] = useState('');
  const [shipperAddress1, setShipperAddress1] = useState('');
  const [shipperAddress2, setShipperAddress2] = useState('');
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeAddress1, setConsigneeAddress1] = useState('');
  const [consigneeAddress2, setConsigneeAddress2] = useState('');
  const [podCode, setPodCode] = useState('');
  const [descriptionOfGoods, setDescriptionOfGoods] = useState('');
  const [grossWt, setGrossWt] = useState(0);
  
  Moment.locale('en');

  var converter = require('number-to-words');

  const capitalizeString = (str) => {

    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2;

  }

  const { id } = useParams();
// alert(id);
  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }

    const collectionDeliveryNoteRequest = axiosCtx.instance.get(`collectionDeliveryNote/show/${id}`);
    // const jobRequest = axiosCtx.instance.get(`job/${jobId}`);

    Promise.all([collectionDeliveryNoteRequest]).then(([collectionDeliveryResponse]) => {

      if (collectionDeliveryResponse.status === 200) {
        let response = collectionDeliveryResponse.data.data;
       console.log(response.category);    
       setdateOfIssue(response.date_of_issue);
       setJobRef(response.job_ref);
       setHawb(response.hawbhcvbNo);
       setvslFlt(response.project.name);
       setPodCode(response.port.code);
       setPartyName(response.party.name);
       setPartyEmail(response.party.email);
       setPrice(response.airwaybill.price);
       setShipperName(response.shipper.name);
       setShipperAddress1(response.shipper.address_1);
       setShipperAddress2(response.shipper.address_2);
       setConsigneeName(response.consignee.name);
       setConsigneeAddress1(response.consignee.address_1);
       setConsigneeAddress2(response.consignee.address_2);
       setGrossWt(response.weight);
       setDescriptionOfGoods(response.description_of_goods);
        setPrefix((response.prefix_id) ? response.prefix_id : '');
        setMawb((response.mawbmcvbNo) ? response.mawbmcvbNo : '');
       
      }else{
        alert('nothing');    
      }
  
    }).catch((error) => {
      // TODO: Handle Error
      console.log(error)
      // alert('Sorry, something went wrong!');
    });

  }, []);

  return (
    <>
      <div className="content pdfViewer">
        <PDFViewer style={styles.viewer}>
          <Document >
            <Page size="A4" style={styles.page} wrap>
              <View style={styles.header} fixed>
                <Image style={styles.logo} src={logo} />

              </View>

              <View>

                <Text style={[ styles.bold, { fontSize: 13, textAlign: 'center',lineHeight: '1.5px' }]}>  COLLECTION/DELIVERY NOTE  </Text>
                <View style='' >
                      <Text style={[ {  lineHeight: '1.5px' }]} >To:{partyName} </Text>
                     
                    </View>
                    <View style='' >
                      <Text style={[ {  lineHeight: '3.5px' }]} >Attn:{partyEmail} </Text>
                     
                    </View>
                    <View>
<Text style={[ { lineHeight: '1.5px' }]}>
    
Please arrange to move the following shipment     </Text>
<Text style={[ {lineHeight: '1.5px' }]}>
Price Agreed: {price} GBP
                </Text>
                </View>
             
              </View>
              <View>
                {/* <View style={styles.row} fixed>
                  <Text style={[styles.thcol, styles.bold, { fontSize: 13, textAlign: 'center' }]}>COLLECTION/DELIVERY NOTE</Text>
                </View> */}

                <View style={styles.row} fixed>
                  {/* <View style={[styles.thcol, { minWidth: '20%', lineHeight: '1.5px' }]}>
                    <Text style={styles.w100}> CUSTOMER NAME & ADDRESS </Text>
                    <Text style={[styles.w100, styles.bold]}> {selectedParty.name} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.address_1}   </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.address_2} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.city} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.country} </Text>
                    <Text style={[styles.w100, styles.bold]}>{selectedParty.vat_number} </Text>
                  </View> */}
                  <View style={[styles.tdcol, { padding: 0, borderLeftColor: '#fff' }]}>
                  {/* <View style={styles.row} >
                      <Text style={styles.thcol}>To </Text>
                      <Text style={styles.thcol}>{partyName} </Text>
                    </View> */}
                    {/* <View style={styles.row} >
                      <Text style={styles.thcol}>Price </Text>
                      <Text style={styles.thcol}>{price} </Text>
                    </View> */}
                    <View style={[styles.row]} >
                      <Text style={styles.thcol}> Date Of Issue </Text>
                      <Text style={styles.thcol}>{dateOfIssue ? Moment(dateOfIssue).format('DD-MM-yyyy') : ''} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>VSL/FLT  </Text>
                      <Text style={styles.thcol}>{vslFlt} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>POD  </Text>
                      <Text style={styles.thcol}>{podCode} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>HAWB NO  </Text>
                      <Text style={styles.thcol}>{hawb} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>MAWB NO  </Text>
                      <Text style={styles.thcol}>{prefix}{mawb} </Text>
                    </View>
                    <View style={styles.row} >
                      <Text style={styles.thcol}>JOB REF  </Text>
                      <Text style={styles.thcol}>{jobRef} </Text>
                    </View>

                    <View style={styles.row} >
                      <Text style={styles.thcol}>Description Of Goods  </Text>
                      <Text style={styles.thcol}>{descriptionOfGoods} </Text>
                    </View>
                    <View style={styles.row} >
                    <Text style={styles.thcol}>  Gross Wt.  </Text>
                    <Text style={styles.thcol}>{grossWt} </Text>
                 </View>

                  </View>
                </View>

                {/* <View style={styles.border} >
                <View style={styles.row} fixed>
                  <Text style={styles.thcol}>  Collection From :{shipperId}   </Text>
                </View>
                <View style={styles.row} fixed>
                  <Text style={styles.thcol}>  Deliver To :{consigneeId}  </Text>
                </View>
                </View> */}
                {/* <View style={[styles.row, styles.bold, { fontSize: '12' }]} >
                  <Text style={[styles.tdcol, { borderRightColor: '#fff' }]}>TOTAL </Text>
                  <Text style={[styles.tdcol, { textAlign: 'right', borderLeftColor: '#fff', paddingRight: 4 }]}>{invoiceCurrency} {parseFloat(totalAmountAfterVat).toFixed(2)}</Text>
                </View> */}

                              {/* <Text style={[{ paddingTop: 5 }]}>TOTAL :  {capitalizeString(converter.toWords(parseFloat(totalAmountAfterVat).toFixed(2)))} {(fraction != 0) ? 'and ' + curText + ' ' + capitalizeString(converter.toWords(Math.round(fraction.toFixed(2) * 100))) + '/00 Only' : 'Only'}    </Text> */}

                              <View style={styles.row} fixed>
                  <View style={[styles.thcol, { minWidth: '20%', lineHeight: '1.5px' }]}>
                    <Text style={styles.w100}> Collection From </Text>
                    <Text style={[styles.w100, styles.bold]}>{shipperName} </Text>
                    <Text style={[styles.w100, styles.bold]}>{shipperAddress1}  </Text>
                    <Text style={[styles.w100, styles.bold]}>{shipperAddress2} </Text>
                    <Text style={[styles.w100, styles.bold]}></Text>
                    <Text style={[styles.w100, styles.bold]}> </Text>
                    <Text style={[styles.w100, styles.bold]}> </Text>
                  </View>
                  <View style={[styles.tdcol, { padding: 0}]}>
                  <View style={[styles.thcol, { minWidth: '20%', lineHeight: '1.5px' }]}>
                    <Text style={styles.w100}> Deliver To </Text>
                    <Text style={[styles.w100, styles.bold]}>{consigneeName} </Text>
                    <Text style={[styles.w100, styles.bold]}> {consigneeAddress1} </Text>
                    <Text style={[styles.w100, styles.bold]}>{consigneeAddress2} </Text>
                    <Text style={[styles.w100, styles.bold]}></Text>
                    <Text style={[styles.w100, styles.bold]}> </Text>
                    <Text style={[styles.w100, styles.bold]}> </Text>
                  </View>

                    </View>

                  </View>

                <Text style={[{ paddingBottom: 5, paddingTop: 5, textAlign: 'center', fontSize: 8, }]}>This is a system generated report which does not require any signature.Any discrepancy should be reported within 7 days from the date of invoice else this will be considered as final.</Text>
              </View>
              
              {/* <View style={[styles.footer, {fontSize: 8, borderColor: '#000', borderTopWidth: 1, }]} render={({ pageNumber, totalPages }) => (
                pageNumber == totalPages && (
                  <View style={[styles.footer, { borderColor: '#000', borderTopWidth: 1,}]} >
                    <Text style={[styles.bold, { fontSize: 8, paddingBottom: 5, paddingTop: 5 }]}>Bank Details (USD): </Text>
                    <Text style={[{marginBottom:3, fontSize: 7 }]}>Sea Trust Shipping Services LLC </Text>
                    <Text style={[{marginBottom:3, fontSize: 7 }]}>RAK BANK: USD A/C # 0242242854002</Text>
                    <Text style={[{marginBottom:3, fontSize: 7 }]}>IBAN: AE42 0400 0002 4224 2854 002</Text>
                    <Text style={[{marginBottom:3, fontSize: 7 }]}>SWIFT : NRAKAEAK</Text>
                  </View>
                )
              )} >
 </View> */}
              <View style={[styles.footer, styles.fixedBottom, { marginTop: 15 }]} fixed >

                <Text style={[{ fontSize: '8.5', paddingBottom: 10, paddingTop: 10, textAlign: 'center' }]}>Warehouse # 366 , Dubai Maritime City. Dubai , UAE . PO BOX 182693 Tel : +971-4-8786950 E-mail : info@seatrustshipping.com Web: www.seatrustshipping.com</Text>
              </View>

            </Page>
          </Document>
        </PDFViewer>
      </div>
    </>
  );
}

export default CollectionDeliveryNotePdf;
