import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import logo from "assets/img/logo.svg";
import menuIcon from "assets/img/menu.svg";
import icon1 from "assets/img/icon1.svg";
import icon2 from "assets/img/icon2.svg";
import icon3 from "assets/img/icon3.svg";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuMasterOpen, setSubMenuMasterOpen] = useState(false);
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo px-3 d-flex justify-content-between">
        <a href="/" className="simple-text logo-normal">
          <img src={logo} alt="react-logo" />
        </a>
        <img src={menuIcon} alt="react-logo" />
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <ul className="nav">
          <li className="">
            <NavLink to="/job-list" className="nav-link" href="/job-list">
              {" "}
              <img src={icon1} alt="" /> <p>Job</p>
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/quotations" className="nav-link" href="/quotations">
              <img src={icon1} alt="" /> <p>Quotations</p>
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/Invoice" className="nav-link" href="/Invoice">
              {" "}
              <img src={icon3} alt="" /> <p>Invoices</p>
            </NavLink>
          </li>
          {/* <li className="">
            <NavLink to="/job-invoice" className="nav-link" href="/job-invoice">
              {" "}
              <img src={icon3} alt="" /> <p>Job With No Invoices</p>
            </NavLink>
          </li> */}
          <li className="">
            <NavLink to="/invoice-job" className="nav-link" href="/invoice-job">
              {" "}
              <img src={icon3} alt="" /> <p>Job Vs Invoices</p>
            </NavLink>
          </li>

          <li className="">
            <a
              className="nav-link"
              onClick={() => setSubMenuOpen(!subMenuOpen)}
            >
              {" "}
              <img src={icon2} alt="icon" /> <p>Reports </p>
            </a>
            <ul className={`nav ${subMenuOpen ? "is-open" : ""}`}>
              <li className="">
                <NavLink to="/job-report" className="nav-link">
                  <p>Job Report</p>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/invoice-report" className="nav-link">
                  <p>Invoice Report</p>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/cost-report" className="nav-link">
                  <p>Cost Report</p>
                </NavLink>
              </li>
            </ul>
          </li>

          {/* <li className="">
            <a className="nav-link" >  <img src={icon4} alt="" />  <p>HRM</p></a>
          </li> */}
          <li className="">
            <NavLink
              to="/operational-cost"
              className="nav-link"
              href="/operational-cost"
            >
              {" "}
              <img src={icon1} alt="" /> <p>Operational Costs</p>
            </NavLink>
          </li>
          <li className="">
            <NavLink to="/warehouse" className="nav-link" href="/warehouse">
              {" "}
              <img src={icon1} alt="" /> <p>Warehouse Management</p>
            </NavLink>
          </li>
          {/* <li className="" >
            <a className="nav-link" onClick={() => setSubMenuMasterOpen(!subMenuMasterOpen)}>  <img src={icon6} alt="icon" />  <p>Master Data management  </p></a>
            <ul className={`nav ${subMenuMasterOpen ? 'is-open' : ''}`}>
              <li className="">
                <NavLink to="master-data" className="nav-link" ><p>List</p></NavLink>
              </li>
              <li className="">
                <a target="_blank" href="http://shipping.cordiacesolutions.com/shipping-api/public/admin" className="nav-link" ><p>Add</p></a>
              </li>
            </ul>
          </li> */}
          {/* <li className="">
            <a className="nav-link" >  <img src={icon8} alt="" />  <p>Website Customisations</p></a>
          </li> */}
          {/* <li className="">
            <NavLink
              to="/shipping-terms"
              className="nav-link"
              href="/shipping-terms"
            >
              <img src={icon1} alt="" /> <p>Shipping Terms</p>
            </NavLink>
          </li> */}
        </ul>

        {/* <Nav>
          {props.routes.map((prop, key) => {
            if(prop.display != 'none'){
            return (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );}
          })}
        </Nav> */}
      </div>
    </div>
  );
}

export default Sidebar;
