// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button } from "react-bootstrap";
// reactstrap components
import { Row, Col, } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import AxiosContext from '../../store/axios-context';
import AuthContext from '../../store/auth-context';
import { useHistory } from 'react-router';
import ReactExport from "react-export-excel";
import Dropdown from "../Fields/Dropdown";
import InputDate from "../Fields/InputDate";
import InputField from "../Fields/InputField";
import moment from 'moment';
import { CSVLink } from "react-csv";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
function createData(id, jobType, project, party, status, action) {
  return {
    id,
    jobType,
    project,
    party,
    status,
    action,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Invoice Number',
  },
  {
    id: 'job id',
    numeric: false,
    disablePadding: true,
    label: 'Job Number',
  },
  {
    id: 'jobType',
    numeric: false,
    disablePadding: false,
    label: 'Job Type',
  },
  {
    id: 'project',
    numeric: false,
    disablePadding: false,
    label: 'project',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Party',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'received-amount',
    numeric: false,
    disablePadding: false,
    label: 'Received Amount',
  },
  {
    id: 'payment-reference',
    numeric: false,
    disablePadding: false,
    label: 'Payment Reference',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <span></span>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function InvoiceReport() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filters, setFilters] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [download_invoices, setDownloadInvoices] = useState([]);
  const [formError, setFormError] = useState(null);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterJobnumber, setFilterJobnumber] = useState('');

  const startDateChangeHandler = (event) => {
    setStartDate(event.target.value);
  };

  const endDateChangeHandler = (event) => {
    setEndDate(event.target.value);
  };

  const getInvoices = () => {
    setFilters(0);
    setLoading(true);
    const params = {
      page: page + 1,
      perPage: rowsPerPage
    };
    axiosCtx.instance.get(`invoice`,{ params })
      .then((response) => {
        console.log('response',response);
        setLoading(false);
        setInvoices(response.data.data);
        setDownloadInvoices(response.data.data);
        setTotalInvoices(response.data.totalInvoices);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        setLoading(false);
      });
  };

  const getStatues = () => {
    axiosCtx.instance.get(`status`)
      .then((response) => {
        console.log('status',response.data.data)
        setStatuses(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };
  const getJobs = () => {
 
    axiosCtx.instance.get(`job`)
      .then((response) => {
       console.log('job',response.data.data)
        setJob(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
      
      });
  };

  const getJobtypes = () => {
    axiosCtx.instance.get(`job-type`)
      .then((response) => {
        setJobtypes(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getParties = () => {
    axiosCtx.instance.get(`party`)
      .then((response) => {
        setParties(response.data.data);
      })
      .catch((error) => {
      });
  };

  const getProjects = () => {
    axiosCtx.instance.get(`project`)
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((error) => {
      });
  };

  const statusChangeHandler = (event, value) => {
    if (value) {
      setStatus(value.id);
    } else {
      setStatus('');

    }
  };

  const projectChangeHandler = (event, value) => {
    if (value) {
      setProject(value.id);
    } else {
      setProject('');

    }
  };

  const subtypeChangeHandler = (event, value) => {
    if (value) {
      setSubtype(value.id);
    } else {
      setSubtype('');
    }

  };

  const getSubtypes = () => {
    axiosCtx.instance.get(`sub-type`)
      .then((response) => {

        setSubtypes(response.data.data);
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
      });
  };

  const jobtypeChangeHandler = (event, value) => {
    if (value) {
      setJobtype(value.id);
    }
    else {
      setJobtype('');
    }
  };

  const partyChangeHandler = (event, value) => {
    // setParty(value.id);
    if (value) {
      setParty(value.id);
    }
    else {
      setParty('');
    }
  };

  const filterSubmit = (event) => {
    // event.preventDefault();
    setFormError(null);
    setLoading(true);
    // Call login API
    const formData = new FormData();
    formData.append("job_number", (filterJobnumber) ? filterJobnumber.id : '');

    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("job_type_id", (jobtype) ? jobtype.id : '');
    formData.append("status_id", (status) ? status.id : '');
    formData.append("project_id", (project) ? project.id : '');
    formData.append("party_id", (party) ? party.id : '');
    formData.append("page", page + 1);
    formData.append("perPage", rowsPerPage);
    setFilters(1);
    axiosCtx.instance.post(`invoice/filter`, formData)
      .then((response) => {
      console.log('invoiceFilter',response);
        setInvoices(response.data.data);
        setDownloadInvoices(response.data.data);
        setTotalInvoices(response.data.totalInvoices);
        setLoading(false);
        filterClose();
      })
      .catch((error) => {
        // setFormError(error.response.data);
        // setLoading(false);
        setLoading(false);
      });
  };

  const [projects, setProjects] = useState([]);
  const [jobtypes, setJobtypes] = useState([]);
  const [jobs, setJob] = useState([]);
  const [parties, setParties] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subtypes, setSubtypes] = useState([]);

  const [status, setStatus] = useState('');
  const [project, setProject] = useState('');
  const [jobtype, setJobtype] = useState('');
  const [subtype, setSubtype] = useState('');
  const [party, setParty] = useState('');

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace('/auth/Login');
    }
    if(filters ==0){
      getInvoices();
  }else{
      filterSubmit();
  }
    // getInvoices();
    getSubtypes();
    getParties();
    getJobtypes();
    getJobs();
    getStatues();
    getProjects();
  }, [page, rowsPerPage,filters]);

  const onEditClickHandler = (item) => {
    console.log(item);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = invoices.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => {
    setShowFilter(false);
  }

  const filterClear = () => {
    setFilters(0);
    setStatus(null);
    setProject(null);
    setJobtype(null);
    setParty(null);
    getInvoices();
    setFilterJobnumber(null);
    setStartDate('');
    setEndDate('');
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => { setAge(event.target.value); };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoices.length) : 0;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let [excelData, setExcelData] = useState([]);
  const sortInvoice = () => {
    let data = Array.isArray(download_invoices) ? [...download_invoices] : [];

    data = data.map(row => ({
      ...row,
      job_type: row.job_type ? row.job_type.type : "",
      project: row.project ? row.project.name : "",
      party: row.party ? row.party.name : "",
      invoice_date: row.invoice_date ? moment(row.invoice_date).format("YYYY-MM-DD") : '',
      currency: row.currency ? row.currency.currency_label : "",
      amount: row.amount ? parseFloat(row.amount).toFixed(2) : "0.00",
      received_amount: row.received_amount ? parseFloat(row.received_amount).toFixed(2) : "0.00",
    }));
  
    setExcelData(data);
  };

  const headers = [
    { label: "Invoice Number", key: "invoice_number" },
    { label: "Job Number", key: "Job" },
    { label: "Job Type", key: "job_type" },
    { label: "Project", key: "project" },
    { label: "Party", key: 'party' },
    { label: "Date", key: 'invoice_date' },
    { label: "Currency", key: 'currency' },
    { label: "Amount", key: 'amount' },
    { label: "Received Amount", key: 'received_amount' },
    { label: "Payment Reference", key: 'payment_reference' },
  ];
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#2f67c9");
  return (
    <div className="content">
       {loading && loading == true ?
        <div className="custom-loader">
          <FadeLoader color={color} loading={loading} css={override} size={150} />
        </div>
        : ''
      }
      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Invoice</h4>
        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1 position-relative">
            <button className="btn btn-simple" onClick={() => setShowFilter((currentShow) => !currentShow)} >
              Filter
            </button>
            <CSVLink data={excelData} headers={headers} className="btn btn-simple" onClick={event => {
              sortInvoice();
              return true;
            }}>
              Download
            </CSVLink>
            {showFilter ? (
              <div className="filter-container p-4 bg-white shadow-sm rounded">
                <Row>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="Start Date"
                      label="Start Date"
                      value={startDate}
                      onChange={startDateChangeHandler}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <InputDate
                      id="date"
                      placeholder="End Date"
                      label="End Date"
                      value={endDate}
                      onChange={endDateChangeHandler}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="jobnumber"
                      placeholder="Job Number"
                      label="job_id"
                      value={filterJobnumber}
                      options={jobs}
                      onChange={(event, newValue) => {
                        setFilterJobnumber(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="statuses"
                      placeholder="Status"
                      label="name"
                      options={statuses}
                      value={status}
                      onChange={(event, newValue) => {
                        setStatus(newValue);
                      }}
                    />

                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="jobtypes"
                      placeholder="Job Type"
                      label="type"
                      options={jobtypes}
                      value={jobtype}
                      onChange={(event, newValue) => {
                        setJobtype(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="projects"
                      placeholder="Project"
                      label="name"
                      options={projects}
                      value={project}
                      onChange={(event, newValue) => {
                        setProject(newValue);
                      }}
                    />
                  </Col>
                  <Col md="6" className="mb-4">
                    <Dropdown
                      id="parties"
                      placeholder="Parties"
                      label="name"
                      options={parties}
                      value={party}
                      onChange={(event, newValue) => {
                        setParty(newValue);
                      }}
                    />
                  </Col>

                </Row>
                <div className="text-right border-top pt-3">
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClear}
                  >
                    Clear
                  </Button>
                  <Button
                    className="my-0 mr-3"
                    variant="secondary"
                    onClick={filterClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="my-0  "
                    variant="primary"
                    onClick={filterSubmit}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          {/* <div className="col-auto my-1">
            <button className="btn btn-primary" onClick={handleShow}>
              Create Invoice
            </button>
          </div> */}
        </div>
      </div>

      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={invoices.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
               {stableSort(invoices, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none" > {row.invoice_number} </TableCell>
                        <TableCell>{row.job.job_id}</TableCell>
                        <TableCell>{row.job_type.type}</TableCell>
                        <TableCell>{row.project.name}</TableCell>
                        <TableCell>{row.party.name}</TableCell>
                        <TableCell>{row.created_at}  </TableCell>
                        <TableCell>{row.currency.currency_label} {Number(row.amount).toFixed(2)}</TableCell>
                        <TableCell> {Number(row.received_amount).toFixed(2)}</TableCell>
                        <TableCell> {row.payment_reference}</TableCell>
                        <TableCell><span className="badge badge-danger py-1 px-2 ">{row.status.name}</span>  </TableCell>

                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={invoices.length}  
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </Box>
    </div>
  );
}
