// import * as React from 'react';
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

import { Button, Modal } from "react-bootstrap";
// reactstrap components
import AxiosContext from "../../store/axios-context";
import AuthContext from "../../store/auth-context";
import { useHistory } from "react-router";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";
import { NavLink } from "react-router-dom";
import Moment from "moment";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function getExactValue(array, orderBy) {
  let data = array;
  orderBy.split(".").forEach((element) => {
    data = data[element];
  });
  return data;
}

function descendingComparator(a, b, orderBy) {
  if (getExactValue(b, orderBy) < getExactValue(a, orderBy)) {
    return -1;
  }
  if (getExactValue(b, orderBy) > getExactValue(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "quote_code",
    numeric: false,
    disablePadding: true,
    label: "Quote Code",
  },
  {
    id: "customer.name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "from.name",
    numeric: false,
    disablePadding: false,
    label: "From",
  },
  {
    id: "to.name",
    numeric: false,
    disablePadding: false,
    label: "To",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "shipmentTerm",
    numeric: false,
    disablePadding: false,
    label: "Shipment Term",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="font-weight-bold"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return <span></span>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function List() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("quote_code");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();
  const [quotes, setQuotes] = useState([]);
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);
  Moment.locale("en");

  const prepareQuotations = () => {
    setLoading(true);
    axiosCtx.instance
      .get(`get-quotation`)
      .then((response) => {
        setLoading(false);
        setQuotes(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == false) {
      history.replace("/auth/Login");
    }
    prepareQuotations();
  }, []);

  const onQuotationEditHandler = (item) => {
    history.push({
      pathname: `quotation/edit/${item.id}`,
      state: { detail: item, page: "index" },
    });
  };

  const onQuotationDeleteHandler = (item) => {
    setDeleteJobId(item);
    handleShowDelete();
  };

  const deleteSubmitHandler = () => {
    setLoading(true);
    if (deleteJobId) {
      axiosCtx.instance
        .get(`delete-quotation?id=${deleteJobId}`)
        .then((response) => {
          setLoading(false);
          setDeleteMessage(response.data.data);
          setDeleteJobId("");
          prepareQuotations();
          handleCloseDelete();
        })
        .catch((error) => {
          setDeleteMessage(error.response.data.errors);
          setLoading(false);
        });
    }
  };

  const onQuotationCopyHandler = (id) => {
    axiosCtx.instance
      .post("copy-quotation", { id: id })
      .then((response) => {
        const data = response.data;
        history.push({
          pathname: `quotation/edit/${data.quoteId}`,
          state: {},
        });
      })
      .catch((error) => {
        alert("Something went wrong");
      });
  };

  const onQuotationPrintHandler = (id) => {
    history.push({
      pathname: `quotation/pdf/${id}`,
      state: {},
    });
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteJobId, setDeleteJobId] = useState("");
  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteMessage("");
    setDeleteJobId("");
  };
  const handleShowDelete = () => setShowDelete(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = quotes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotes.length) : 0;

  let [loading, setLoading] = useState(false);

  return (
    <div className="content">
      {loading && loading == true ? (
        <div className="custom-loader">
          <FadeLoader
            color="#2f67c9"
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : (
        ""
      )}

      <div className="d-flex justify-content-between align-items-center border-bottom mb-4">
        <h4 className="font-weight-bold m-0">Quote List</h4>

        <div className="form-row align-items-center justify-content-end">
          <div className="col-auto my-1">
            <NavLink to="/quotation/create" className="btn btn-primary">
              Create Quote
            </NavLink>
          </div>
        </div>

        {/* ******************** delete modal start here **************** */}
        <Modal show={showDelete} onHide={handleCloseDelete} size="lg">
          <Modal.Header className="p-4">
            <Modal.Title className="m-0">Delete Job </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Modal.Title className="m-0">
              Are you sure to do this ?{" "}
            </Modal.Title>
            <p>This will remove all data related to this job</p>
            <p>{deleteMessage}</p>
          </Modal.Body>
          <Modal.Footer className="p-4">
            <Button
              className="mr-3"
              variant="secondary"
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={deleteSubmitHandler}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={quotes.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(quotes, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell>{row.quote_code}</TableCell>
                        <TableCell>{row.customer.name}</TableCell>
                        <TableCell>{row.from.name}</TableCell>
                        <TableCell>{row.to.name}</TableCell>
                        <TableCell>
                          {Moment(row.created_at).format("DD-MM-yyyy")}
                        </TableCell>
                        <TableCell>{row.shipement.title}</TableCell>
                        <TableCell className="text-center">
                          <button
                            onClick={() => onQuotationEditHandler(row)}
                            className="btn btn-primary btn-sm font-weight-bold my-2 mx-1"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => onQuotationDeleteHandler(row.id)}
                            className="btn btn-danger btn-sm font-weight-bold my-2 mx-1"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => onQuotationCopyHandler(row.id)}
                            className="btn btn-info btn-sm font-weight-bold my-2 mx-1"
                          >
                            Copy
                          </button>
                          <button
                            onClick={() => onQuotationPrintHandler(row.id)}
                            className="btn btn-secondary btn-sm font-weight-bold my-2 mx-1"
                          >
                            Print
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={quotes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
}
