const helpers = {
  getInputValue: (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "");
    if (value) {
      return value;
    }
    return 0;
  },
  getIntVal: (number) => {
    return Number(parseFloat(number).toFixed(4));
  },
};

export default helpers;
