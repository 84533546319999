import JobInvoiceList from "components/JobInvoice";
import Wrapper from "components/Wrapper";
import React from "react";

export default function Quotations() {
  return (
    <Wrapper>
      <div className="content">
        <JobInvoiceList />
      </div>
    </Wrapper>
  );
}
